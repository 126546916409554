<template>
    <modal ref="modalCrearDelivery" titulo="Crear Delivery" icon="moped" adicional="Crear" :cargado-modal="cargando" no-aceptar tamano="modal-lg" @adicional="crearDelivery">
        <ValidationObserver ref="validacion">
            <div class="row mx-0 justify-center">
                <div class="col-auto px-4" />
                <div class="col">
                    <div class="row mx-0">
                        <div class="col-auto">
                            <slim-cropper ref="foto_cedis" :options="slimOptions" class="border cr-pointer" style="height:150px;background:#F8F9FF;width:150px;border-radius:12px;" />
                        </div>
                        <div class="col px-2">
                            <p class="text-general f-14 pl-3">
                                Nombre
                            </p>
                            <ValidationProvider v-slot="{errors}" rules="required|max:25" name="nombre">
                                <el-input v-model="model.nombre" size="small" maxlength="25" class="w-100" />
                                <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                            </ValidationProvider>
                            <p class="text-general f-14 pl-3 mt-3">
                                Documento de identidad
                            </p>
                            <ValidationProvider v-slot="{errors}" rules="required|numeric|max:20" name="documento de identidad">
                                <el-input v-model="model.documento_identidad" size="small" maxlength="20" class="w-100" />
                                <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                            </ValidationProvider>
                        </div>
                    </div>
                    <div class="row mx-0 mt-4">
                        <div class="col">
                            <p class="text-general f-14">
                                Seleccione el tipo de Vehículo
                            </p>
                            <div>
                                <i
                                class="mr-3 cr-pointer icon-moped"
                                :class="`${model.aplica_moto ? 'text-general-red' : 'text-general2'} f-25`"
                                @click="model.aplica_moto = !model.aplica_moto"
                                />
                                <i
                                class="mr-3 cr-pointer icon-car"
                                :class="`${model.aplica_carro ? 'text-general-red' : 'text-general2'} f-25`"
                                @click="model.aplica_carro = !model.aplica_carro"
                                />
                                <i
                                class="mr-3 cr-pointer icon-van"
                                :class="`${model.aplica_camioneta ? 'text-general-red' : 'text-general2'} f-25`"
                                @click="model.aplica_camioneta = !model.aplica_camioneta"
                                />
                                <i
                                class="mr-3 cr-pointer icon-truck-outline"
                                :class="`${model.aplica_camion ? 'text-general-red' : 'text-general2'} f-25`"
                                @click="model.aplica_camion = !model.aplica_camion"
                                />
                            </div>
                            <span v-if="validarVehivulo" class="text-danger w-100 f-11"> Este campo es requerido </span>
                        </div>
                        <div class="col pr-2">
                            <p class="text-general f-14 pl-3">
                                Vigencia permiso de conducción
                            </p>
                            <ValidationProvider v-slot="{errors}" rules="required" name="fecha vigencia">
                                <el-date-picker v-model="model.fecha_conducir" size="small" type="date" format="dd - MM - yyyy" value-format="yyyy-MM-dd" class="w-100" />
                                <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                            </ValidationProvider>
                        </div>
                    </div>
                    <div class="row mx-0 my-4">
                        <div class="col">
                            <p class="text-general f-14 pl-3">
                                Fecha de vinculación
                            </p>
                            <ValidationProvider v-slot="{errors}" rules="required" name="fecha vinculación">
                                <el-date-picker v-model="model.fecha_vinculacion" size="small" type="date" format="dd - MM - yyyy" value-format="yyyy-MM-dd" class="w-100" :picker-options="pickerOptions" />
                                <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                            </ValidationProvider>
                        </div>
                        <div class="col pr-2">
                            <p class="text-general f-14 pl-3">
                                Fecha de nacimiento
                            </p>
                            <ValidationProvider v-slot="{errors}" rules="required" name="fecha nacimiento">
                                <el-date-picker v-model="model.fecha_nacimiento" size="small" type="date" format="dd - MM - yyyy" value-format="yyyy-MM-dd" class="w-100" :picker-options="pickerOptions" />
                                <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                            </ValidationProvider>
                        </div>
                    </div>
                    <div class="row mx-0">
                        <div class="col">
                            <p class="text-general pl-3 f-14">
                                Añadir cedis asociado
                            </p>
                            <el-select v-model="value" filterable class="w-100" size="small" @change="agregarCedis">
                                <el-option
                                v-for="item in cedisNoSeleccionados"
                                :key="item.id"
                                :label="item.nombre"
                                :value="item.id"
                                />
                            </el-select>
                            <span v-if="validarCedis" class="text-danger w-100 f-11"> Este campo es requerido </span>
                        </div>
                        <div class="col pt-4 pr-2">
                            <div v-for="(data, d) in cedisSeleccionados" :key="d" class="row mx-0 align-items-center">
                                <div class="col-auto d-middle">
                                    <i
                                    class="f-20 cr-pointer"
                                    :class="`${data.principal ? 'icon-star text-general-red' : 'icon-star-empty text-general'}`"
                                    @click="clicks(data)"
                                    />
                                </div>
                                <div class="col px-0 d-middle text-general f-15">
                                    {{ data.nombre }}
                                </div>
                                <div class="col-auto d-middle">
                                    <i class="icon-cancel-circled f-20 cr-pointer" @click="eliminarCedi(d)" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mx-0 my-3">
                        <div class="col">
                            <p class="text-general pl-3 f-14">
                                Número telefónico
                            </p>
                            <ValidationProvider v-slot="{errors}" :rules="{required: true, max:10, numeric: true }" name="número telefónico">
                                <el-input v-model="model.telefono" size="small" maxlength="10" />
                                <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                            </ValidationProvider>
                        </div>
                        <div class="col pr-2">
                            <p class="text-general pl-3 f-14">
                                Correo electrónico
                            </p>
                            <ValidationProvider v-slot="{ errors, failedRules }" rules="required|email|max:70" name="correo electrónico">
                                <el-input v-model="model.correo" type="email" maxlength="70" size="small">
                                    <i v-if="failedRules.email" slot="suffix" class="icon-attention-alt text-danger el-input__icon f-17" />
                                    <i v-else-if="model.correo != ''" slot="suffix" class="icon-ok-circled-outline text-success el-input__icon f-17" />
                                </el-input>
                                <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                            </ValidationProvider>
                        </div>
                    </div>
                    <div class="row mx-0 my-3">
                        <div class="col">
                            <p class="f-14 pl-3 text-general">
                                Contraseña
                            </p>
                            <ValidationProvider v-slot="{errors}" rules="required|max:200|min:4|max:20" name="contraseña" vid="confirmation">
                                <el-input v-model="model.password" class="w-100" size="small" type="password" minlength="4" maxlength="20" @change="cambioPassword" />
                                <p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
                            </ValidationProvider>
                        </div>
                        <div class="col pr-2">
                            <p class="f-14 pl-3 text-general">
                                Repetir Contraseña
                            </p>
                            <ValidationProvider v-slot="{errors}" rules="required|max:200|confirmed:confirmation" name="confirmar contraseña">
                                <el-input v-model="model.password_confirmation" size="small" class="w-100" type="password" maxlength="201" @change="cambioPassword" />
                                <p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
                            </ValidationProvider>
                        </div>
                    </div>
                </div>
                <div class="col-auto px-4" />
            </div>
        </ValidationObserver>
    </modal>
</template>

<script>
import { mapGetters } from 'vuex'
import Delivery from "~/services/delivery/index";
export default {
    data(){
        return {
            input: '',
            slimOptions: {
                ratio: '1:1',
                label:'Subir imagen',
                buttonCancelLabel: 'Cancelar',
                buttonConfirmLabel: 'ok',
                buttonUploadLabel: 'Ok',
            },
            pickerOptions: {
                disabledDate(time){
                    return time.getTime() > Date.now()
                },
            },
            value: null,
            cambioPass: 0,
            model: {
                nombre: '',
                telefono: '',
                correo: '',
                foto: '',
                password: '',
                password_confirmation: '',
                id_cedis: null,
                fecha_conducir: '',
                fecha_nacimiento: '',
                fecha_vinculacion: '',
                documento_identidad: '',
                aplica_moto: false,
                aplica_carro: false,
                aplica_camioneta: false,
                aplica_camion: false,
            },
            ids_cedis: [],
            cedisSeleccionados: [],
            cargando: false,
            validarVehivulo: false,
            validarCedis: false,
        }
    },
    computed: {
        ...mapGetters({
            cedis:'cedis/cedis',
        }),
        cedisCopy(){
            return this.cedis.map(el => ({...el, principal: false}))
        },
        cedisNoSeleccionados(){
            return this.cedisCopy.filter(el => !this.ids_cedis.includes(el.id) && el.id != 0)
        },
        validateTransport(){
            return this.model.aplica_moto || this.model.aplica_carro || this.model.aplica_camioneta || this.model.aplica_camion
        },
    },
    watch: {
        validateTransport(val){
            this.validarVehivulo = !val
        },
        cedisSeleccionados(val){
            this.validarCedis = !val.length
        },
    },
    methods: {
        toggle(){
            // this.limpiar()
            this.$refs.modalCrearDelivery.toggle();
        },
        clicks(cedi){
            this.cedisSeleccionados.map(el => el.principal = el.id == cedi.id)
            this.model.id_cedis = cedi.id
        },
        eliminarCedi(id){
            const spliced = this.cedisSeleccionados.splice(id,1)
            this.ids_cedis.splice(id,1)
            if (spliced[0].principal && this.cedisSeleccionados.length) this.cedisSeleccionados[0].principal = true
        },

        async crearDelivery(){
            try {

                const valid = await this.$refs.validacion.validate()
                /* console.log(!this.cedisSeleccionados >= 1, 'cedisSeleccionados')
                console.log(!valid, 'valid');
                if(!this.cedisSeleccionados.length) return this.notificacion('', 'Debe seleccionar un cedis', 'error') */
                if(!this.validateTransport) this.validarVehivulo = true
                if(!this.cedisSeleccionados.length) this.validarCedis = true
                if(!valid || !this.cedisSeleccionados.length || !this.validateTransport){
                    this.notificacion('Mensaje', 'campos obligatorios', 'warning')
                    return false
                }
                const archivo = this.$refs.foto_cedis.instanciaCrop.dataBase64.output
                this.model.foto = archivo.image
                this.model.cambioPass = this.cambioPass
                this.model.user_cedis = this.ids_cedis
                this.cargando = true


                const {data} = await Delivery.crearDelivery(this.model);
                this.notificacion('Mensaje', 'Creado exitosamente', 'success');
                this.cedisSeleccionados = [];
                this.$emit('actualizar');
                this.$refs.modalCrearDelivery.toggle();
            } catch (e){
                this.error_catch(e)
            }finally{
                this.cargando = false
            }
        },
        limpiar(){
            this.model = {
                nombre: '',
                telefono: '',
                correo: '',
                foto: '',
                password: '',
                id_cedis: null,
                fecha_nacimiento: '',
                fecha_vinculacion: '',
                fecha_conducir: '',
                documento_identidad: '',
                aplica_moto: false,
                aplica_carro: false,
                aplica_camioneta: false,
                aplica_camion: false,
            }
            this.$refs.validacion.reset()
            this.$refs.foto_cedis.instanciaCrop.remove()
            this.validarVehivulo = false
            this.validarCedis = false
        },
        cambioPassword(){
            this.cambioPass = 1
        },
        agregarCedis(val){
            let cedi = this.cedisCopy.find(el => el.id == val)
            this.ids_cedis.push(val)
            this.model.id_cedis = this.cedisSeleccionados.length ? this.model.id_cedis : cedi.id
            cedi.principal = this.cedisSeleccionados.length ? false : true
            this.cedisSeleccionados.push(cedi)
            this.value = null
        }
    }
}
</script>
<style lang="css" scoped>
.text-blue{
    color: #02D6BC;
}
</style>
